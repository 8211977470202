

import isVerb from './is-verb';
import build from './build';
import irregularsList from './irregulars-list';
import isIrregular from './is-irregular';

/**
 * Conjugar qualquer verbo (regular ou irregular).
 *
 * @param {string} verb Um verbo qualquer.
 * @returns {object} Toda as conjugações.
 */
export default function conjugar(verb) {
  if (!isVerb(verb)) {
    throw new Error('Invalid verb');
  }

  return isIrregular(verb)
    ? irregularsList[verb]
    : build(verb);
}
