const ACCENTS = [
  "á",
  "é",
  "í",
  "ó",
  "ú",
  "à",
  "ç",
  "ê",
  "î",
  "ô",
  "ã",
];

export default ACCENTS;