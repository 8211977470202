import { useState, useEffect, useRef } from "react";
import './App.css';
import VERBS from './global/verbs';
import PERSONS from './global/persons';
import TENSES from './global/tenses';
import ACCENTS from './global/accents';
import conjugar from './components/conjugador';
import { IoCheckmarkCircleSharp, IoCloseCircle } from 'react-icons/io5';
import Confetti from 'react-confetti';

function getVerb() {
  return VERBS[Math.floor(Math.random() * VERBS.length)];
}

function App() {
  const [verb, setVerb] = useState("");
  const [shouldReset, setShouldReset] = useState(true);
  const [tense, setTense] = useState("PRESENTE");
  const [person, setPerson] = useState(0);
  const [personText, setPersonText] = useState(getPersonText(person));
  const [answer, setAnswer] = useState("");
  const [userInput, setUserInput] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [verbCount, setVerbCount] = useState(0);
  const [doConfetti, setDoConfetti] = useState(false);
  const input = useRef();

  function handleTenseClick(tense) {
    const verbs = conjugar(verb)[TENSES[tense]];
    setTense(tense);
    setAnswer(verbs[person]);
    switchPerson(0);
  }

  function getPersonText(number) {
    if (number === 2 || number === 5) {
      const personArray = PERSONS.get(number);
      return personArray[Math.floor(Math.random() * personArray.length)];
    }
    return PERSONS.get(number);
  }

  function handleInput(e, newValue = "") {
    const value = newValue || e?.target.value;
    setUserInput(value);
    if (answer.length === value.length) {
      setAnswerCorrect(answer === value);
    } else if (answerCorrect || answerCorrect === false) {
      setAnswerCorrect(null);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setFormSubmitted(true);
  }

  function getNewPerson() {
    return person < PERSONS.size - 1 ? person + 1 : 0;
  }

  function switchPerson(newPerson) {
    setUserInput("");
    setPerson(newPerson);
    setAnswerCorrect(null);
    setPersonText(getPersonText(newPerson));
    setShowAnswer(false);
  }

  function handleWrongAnswerClick() {
    setShowAnswer(true);
  }

  function handleAccentClick(accent) {
    if (userInput.length < answer.length) {
      const newInput = `${userInput}${accent}`;
      setUserInput(newInput);
      handleInput(null, newInput);
    }
    input.current.focus();
  }

  function stopConfetti() {
    setDoConfetti(false);
  }

  useEffect(() => {
    if (shouldReset) {
      const newVerb = getVerb();
      setVerb(newVerb);
      setShouldReset(false);
      setAnswer(conjugar(newVerb)[TENSES[tense]][person]);
    }
    if (formSubmitted) {
      if (answer && userInput) {
        if (answer === userInput) {
          const newPerson = getNewPerson();
          if (newPerson === 0) {
            switchPerson(newPerson);
            setShouldReset(true);
            setVerbCount(verbCount + 1);
            setDoConfetti(true);
          } else {
            const newAnswer = conjugar(verb)[TENSES[tense]][newPerson]
            switchPerson(newPerson);
            setAnswer(newAnswer);
          }
        } else {
          setAnswerCorrect(false);
        }
      }
      setFormSubmitted(false);
    }
  // eslint-disable-next-line
  }, [formSubmitted, answer]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="button-container">
            {Array.from(Object.keys(TENSES), tenseItem => <button className={`button ${tenseItem === tense ? "active" : ""}`} key={tenseItem} onClick={() => handleTenseClick(tenseItem)}>{tenseItem}</button>)}
          </div>
        </div>
      </header>
      <div className="App-center">
        {doConfetti && (
          <Confetti 
            onConfettiComplete={stopConfetti}
            recycle={false}
            gravity={0.4}
          />
        )}
        <div className="container">
          <p className={`answer answer-infinitive answer-infinitive-${person}`}>
            {verb}
          </p>
          <div>
            <form className="answer-form" style={{ position: "relative"}} onSubmit={handleSubmit}>
              <span className="answer answer-person">{personText} &nbsp;</span>
              <input ref={input} className="answer answer-input" name="answer" onInput={handleInput} value={userInput} maxLength={answer?.length}/>
              
              {answerCorrect && <span onClick={handleSubmit} className="answer-icon" style={{ color: "#1d9e44", cursor: "pointer" }}><IoCheckmarkCircleSharp /></span>}
              {answerCorrect === false && <span onClick={handleWrongAnswerClick} className="answer-icon" style={{ color: "rgb(158, 29, 29)" }}><IoCloseCircle /></span>}
            </form>
            {showAnswer && <p className="answer answer-hint">{answer}</p>}
          </div>
        </div>
      </div>
      <footer className="App-footer">
        <div className="container">
          <div className="flex-center flex-wrap">
            {ACCENTS.map(accent => <button key={accent} onClick={() => handleAccentClick(accent)} className="button button-accent">{accent}</button>)}
          </div>
          <div className="footer-stats">
            <p>Verbs completed: {verbCount}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
