

import parseVerb from './parse-verb';
import buildAr from './suffixes/build-ar';
import buildEr from './suffixes/build-er';
import buildIr from './suffixes/build-ir';
import buildPor from './suffixes/build-por';

/**
 * Obter toda a conjugação de um verbo.
 *
 * Somente para verbos terminados em -ar, -er, ir ou -pôr.
 *
 * @param {string} verb Um verbo.
 * @returns {object} Toda a conjugação do verbo.
 */
 export default function build(verb) {
  const { prefix, suffix } = parseVerb(verb);

  switch (suffix) {
    case 'ar':
      return buildAr(prefix);
    case 'er':
      return buildEr(prefix);
    case 'ir':
      return buildIr(prefix);
    default: // pôr
      return buildPor(prefix);
  }
}
